import { Create,useAutocomplete  } from "@refinedev/mui";
import { Autocomplete, Box, Grid, InputAdornment, TextField} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, usePermissions, useTranslate } from "@refinedev/core";
import { Controller, FieldValues } from "react-hook-form";
import React, { useState } from "react";
import { IAppPermission } from "../../interfaces";
import { canCreateOrder } from "../../utils";
//import dayjs from "dayjs";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import {getAuth, listObrasUsuario, listPerfisObrasRestrita } from "../../utils/userspermissions";


export const PedidoCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const auth =   getAuth();
    const obrasUsuario = listObrasUsuario();

    const [currentdatetime, setCurrentdatetime] = useState(new Date());

    let {permissionsData} : IAppPermission = {};
    permissionsData = usePermissions()?.data;

    const {
        refineCore: { formLoading, onFinish, redirect },
        register,
        control,
        formState: { errors },
        handleSubmit
    } = useForm();

    const onFinishHandler = async (fieldsData: FieldValues) => {  
        onFinish(fieldsData).then(values => {
            redirect("edit", values?.data?.id);
        })  
    };

    const { autocompleteProps: obraAutocompleteProps } = useAutocomplete({
        resource: "obras",
        filters:[
            {
                field: "id",
                operator: "in",
                value: (listPerfisObrasRestrita.includes(auth.roles)?obrasUsuario:[])
            }
        ]
    });

    const { autocompleteProps: deptoAutocompleteProps } = useAutocomplete({
        resource: "departamentos",
    });


    const canView = ():boolean=>{
        return ( 
                permissionsData?.status_profile?.find(
                    (item:any)=>item.idStatus===1
                )
                ?.acessoLeitura
            )  
    };

    const canEdit = ():boolean=>{
        return (
                permissionsData?.status_profile?.find(
                        (item:any)=>item.idStatus===1
                    )
                ?.acessoEdicao
            )        
    };

    const canApprove = ():boolean=>{
        return (
                permissionsData?.status_profile?.find(
                        (item:any)=>item.idStatus===1
                    )
                ?.acessoAprovacao
            )        
    };


    return (
        <Create 
            isLoading={formLoading} 
            saveButtonProps={{
                                onClick:handleSubmit(onFinishHandler),
                                disabled: !canCreateOrder()                                
                            }}  
        >

            <Box
                    component="form"
                    //sx={{ display: "flex", flexDirection: "column",  }}
                    //sx={{ display: "flex", flexDirection: {xs:"column",sm:"row"} }}
                    // sx={{ 
                    //         display: "grid", 
                    //         gridTemplateColumns: "repeat(3, 1fr)",
                    //         gridGap: "10px",
                    //     }}
                    autoComplete="off"                             
                >

                <Grid container columns={{ xs: 4, sm: 8, md: 12, lg: 24, xl:24}} spacing={2} >                                           
                    <Grid item xs={4} sm={8} md={3} lg={6} xl={6}>
                            <TextField
                                {...register("dhPedido", {
                                    //required: "This field is required",
                                    valueAsDate:true
                                })}
                                error={!!(errors as any)?.dhPedido}
                                helperText={(errors as any)?.dhPedido?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label={translate("pages.pedidos.fields.dhPedido")}
                                name="dhPedido"
                                value={dayjs(currentdatetime).format("DD/MM/YYYY HH:mm:ss")}
                                disabled={true}
                                sx={{
                                    
                                }}
                                tabIndex={1}
                            />
                    </Grid>
                    <Grid item xs={4} sm={8} md={9} lg={18} xl={18}>
                        <Controller
                                control={control}
                                name="idObra"
                                rules={{ required: translate("validations.requiredField") }}
                                // eslint-disable-next-line
                                defaultValue={null as any}                                    
                                render={({ field }) => (
                                    <Autocomplete
                                        {...obraAutocompleteProps}
                                        {...field}
                                        tabIndex={2}
                                        fullWidth
                                        onChange={(_, value) => {
                                            field.onChange(value);
                                        }}
                                        getOptionLabel={(item) => {
                                            if(typeof(item) === 'object'){
                                                return(
                                                    item.id + ' - ' +
                                                    obraAutocompleteProps?.options?.find(
                                                        (p) =>
                                                            p?.id?.toString() ===
                                                            item?.id?.toString(),
                                                    )?.nomeObra ?? ""
                                                );
                                            }else{
                                                return(
                                                    item + ' - ' +
                                                    obraAutocompleteProps?.options?.find(
                                                        (p) =>
                                                            p?.id?.toString() ===
                                                            item?.toString(),
                                                    )?.nomeObra ?? ""
                                                );
                                            }
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            value === undefined ||
                                            option?.id?.toString() === value?.toString()
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={translate("pages.pedidos.fields.idObra")}
                                                margin="normal"
                                                variant="outlined"
                                                error={!!(errors as any)?.idObra}
                                                helperText={
                                                    (errors as any)?.idObra?.message
                                                }
                                                fullWidth
                                                required
                                            />
                                        )}
                                    />
                                )}
                            />  
                    </Grid>


                        {/* <Controller
                            control={control}
                            name="idDepartamento"
                            rules={{ required: translate("validations.requiredField") }}
                            // eslint-disable-next-line
                            defaultValue={null as any}
                            render={({ field }) => (
                                <Autocomplete
                                    {...deptoAutocompleteProps}
                                    {...field}
                                    onChange={(_, value) => {
                                        field.onChange(value);
                                    }}
                                    getOptionLabel={(item) => {
                                        if(typeof(item) === 'object'){
                                            return(
                                                item.id + ' - ' +
                                                deptoAutocompleteProps?.options?.find(
                                                    (p) =>
                                                        p?.id?.toString() ===
                                                        item?.id?.toString(),
                                                )?.nomeDepartamento ?? ""
                                            );
                                        }else{
                                            return(
                                                item + ' - ' +
                                                deptoAutocompleteProps?.options?.find(
                                                    (p) =>
                                                        p?.id?.toString() ===
                                                        item?.toString(),
                                                )?.nomeDepartamento ?? ""
                                            );
                                        }
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                        value === undefined ||
                                        option?.id?.toString() === value?.toString()
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate("pages.pedidos.fields.idDepartamento")}
                                            margin="normal"
                                            variant="outlined"
                                            error={!!(errors as any)?.idDepartamento}
                                            helperText={
                                                (errors as any)?.idDepartamento?.message
                                            }
                                            required
                                        />
                                    )}
                                />
                            )}
                        />   */}


                        {/*
                            DatePicker component is not included in "@refinedev/mui" package.
                            To use a <DatePicker> component, you can follow the official documentation for Material UI.

                            Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                        */}

                        {/* <DatePicker
                            {...register("dhPedido", {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.dhPedido}
                            helperText={(errors as any)?.dhPedido?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="datetime-local"
                            label={translate("pages.pedidos.fields.dhPedido")}
                            name="dhPedido"                    
                        /> */}


                    <Grid item xs={4} sm={8} md={6} lg={12} xl={12}  >
                        <TextField
                            {...register("numeroContrato", {
                                required: "This field is required",
                                valueAsNumber: true,
                            })}
                            error={!!(errors as any)?.numeroContrato}
                            helperText={(errors as any)?.numeroContrato?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            label={translate("pages.pedidos.fields.numeroContrato")}
                            name="numeroContrato"
                            tabIndex={3}
                        />
                    </Grid>


                    <Grid item xs={4} sm={8} md={6} lg={12} xl={12} >
                        <TextField
                            {...register("numeroPedido", {
                                required: "This field is required",
                                valueAsNumber: true,
                            })}
                            error={!!(errors as any)?.numeroPedido}
                            helperText={(errors as any)?.numeroPedido?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            label={translate("pages.pedidos.fields.numeroPedido")}
                            name="numeroPedido"
                            tabIndex={4}
                        />
                    </Grid>


                    <Grid item xs={4} sm={8} md={3} lg={6} xl={6}>
                        <TextField
                            {...register("codigoInternoFornecedor", {
                                required: "This field is required",
                                valueAsNumber: true,
                            })}
                            error={!!(errors as any)?.codigoInternoFornecedor}
                            helperText={
                                (errors as any)?.codigoInternoFornecedor?.message
                            }
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            label={translate("pages.pedidos.fields.codigoInternoFornecedor")}
                            name="codigoInternoFornecedor"
                            tabIndex={5}
                        />
                    </Grid>

                    <Grid item xs={4} sm={8} md={9} lg={18} xl={18}>
                        <TextField
                            {...register("nomeFornecedor", {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.nomeFornecedor}
                            helperText={(errors as any)?.nomeFornecedor?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label={translate("pages.pedidos.fields.nomeFornecedor")}
                            name="nomeFornecedor"
                            tabIndex={6}
                        />  
                    </Grid>                    



 
                        {/* <TextField
                            {...register("numeroTelefoneFornecedor", {
                                required: "This field is required",
                                valueAsNumber: true,
                            })}
                            error={!!(errors as any)?.numeroTelefoneFornecedor}
                            helperText={
                                (errors as any)?.numeroTelefoneFornecedor?.message
                            }
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            label={translate("pages.pedidos.fields.numeroTelefoneFornecedor")}
                            name="numeroTelefoneFornecedor"
                        />                           
                        <TextField
                            {...register("nomeContatoFornecedor", {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.nomeContatoFornecedor}
                            helperText={(errors as any)?.nomeContatoFornecedor?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label={translate("pages.pedidos.fields.nomeContatoFornecedor")}
                            name="nomeContatoFornecedor"
                        />  */}
                        {/* <TextField
                            {...register("nuPercentualMaoObra", {
                                required: "This field is required",
                                valueAsNumber: true,
                            })}
                            error={!!(errors as any)?.nuPercentualMaoObra}
                            helperText={(errors as any)?.nuPercentualMaoObra?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}                            
                            label={translate("pages.pedidos.fields.nuPercentualMaoObra")}
                            name="nuPercentualMaoObra"
                        />   
                        <TextField
                            {...register("valorMaoObra", {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.valorMaoObra}
                            helperText={(errors as any)?.valorMaoObra?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            variant="outlined"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>
                            }}                             
                            label={translate("pages.pedidos.fields.valorMaoObra")}
                            name="valorMaoObra"
                        />
                        <TextField
                            {...register("nuPercentualMaterial", {
                                required: "This field is required",
                                valueAsNumber: true,
                            })}
                            error={!!(errors as any)?.nuPercentualMaterial}
                            helperText={(errors as any)?.nuPercentualMaterial?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}                          
                            label={translate("pages.pedidos.fields.nuPercentualMaterial")}
                            name="nuPercentualMaterial"
                        />
                        <TextField
                            {...register("valorMaterial", {
                                required: "This field is required",
                                valueAsNumber: true,
                            })}
                            error={!!(errors as any)?.valorMaterial}
                            helperText={(errors as any)?.valorMaterial?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            variant="outlined"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>
                            }}                            
                            label={translate("pages.pedidos.fields.valorMaterial")}
                            name="valorMaterial"
                        /> */}


                    <Grid item xs={4} sm={8} md={6} lg={12} xl={12}>
                        <TextField
                            {...register("dtInicio", {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.dtInicio}
                            helperText={(errors as any)?.dtInicio?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            label={translate("pages.pedidos.fields.dtInicio")}
                            name="dtInicio"
                            tabIndex={7}
                        />               
                    </Grid>

                    <Grid item xs={4} sm={8} md={6} lg={12} xl={12}>           
                        <TextField
                            {...register("dtTermino", {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.dtTermino}
                            helperText={(errors as any)?.dtTermino?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            label={translate("pages.pedidos.fields.dtTermino")}
                            name="dtTermino"
                            tabIndex={8}
                        />    
                    </Grid>                    
 

                        {/* <TextField
                            {...register("numeroPedidoSuprimentos", {
                                required: "This field is required",
                                valueAsNumber: true,
                            })}
                            error={!!(errors as any)?.numeroPedidoSuprimentos}
                            helperText={
                                (errors as any)?.numeroPedidoSuprimentos?.message
                            }
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            label={translate("pages.pedidos.fields.numeroPedidoSuprimentos")}
                            name="numeroPedidoSuprimentos"
                        />   
                        <TextField
                            {...register("dtEmissaoSuprimentos", {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.dtEmissaoSuprimentos}
                            helperText={(errors as any)?.dtEmissaoSuprimentos?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            label={translate("pages.pedidos.fields.dtEmissaoSuprimentos")}
                            name="dtEmissaoSuprimentos"
                        />    */}




                    <Grid item xs={4} sm={8} md={6} lg={12} xl={12}>
                        <TextField
                            {...register("nuPercentualCaucao", {
                                required: "This field is required",
                                valueAsNumber: true,
                            })}
                            error={!!(errors as any)?.nuPercentualCaucao}
                            helperText={(errors as any)?.nuPercentualCaucao?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}                         
                            label={translate("pages.pedidos.fields.nuPercentualCaucao")}
                            name="nuPercentualCaucao"
                            tabIndex={9}
                        />  
                    </Grid>

                    <Grid item xs={4} sm={8} md={6} lg={12} xl={12}>
                        <TextField
                            {...register("valorAtualContrato", {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.valorAtualContrato}
                            helperText={(errors as any)?.valorAtualContrato?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            variant="outlined"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>
                            }}                          
                            label={translate("pages.pedidos.fields.valorAtualContrato")}
                            name="valorAtualContrato"
                            tabIndex={10}
                        />
                    </Grid>                    
                    

                    <Grid item xs={4} sm={8} md={12} lg={24} xl={24}>
                        <TextField
                                {...register("observPedido", {
                                    required: "This field is required",
                                })}
                                error={!!(errors as any)?.observPedido}
                                helperText={(errors as any)?.observPedido?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                multiline
                                minRows={3}
                                maxRows={3}
                                label={translate("pages.pedidos.fields.observPedido")}
                                name="observPedido"
                                inputProps={{
                                    maxLength: 120,
                                }}        
                                tabIndex={11}                                        
                            />
                    </Grid>



                        {/* <TextField
                            {...register("valorAdicional", {
                                required: "This field is required",
                                valueAsNumber: true,
                            })}
                            error={!!(errors as any)?.valorAdicional}
                            helperText={(errors as any)?.valorAdicional?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            variant="outlined"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>
                            }}                          
                            label={translate("pages.pedidos.fields.valorAdicional")}
                            name="valorAdicional"
                        />         
                        <TextField
                            {...register("valorNovoContrato", {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.valorNovoContrato}
                            helperText={(errors as any)?.valorNovoContrato?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            variant="outlined"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>
                            }}                          
                            label={translate("pages.pedidos.fields.valorNovoContrato")}
                            name="valorNovoContrato"
                        /> */}
                        {/* <TextField
                            {...register("numeroContratoIncorporator", {
                                required: "This field is required",
                                valueAsNumber: true,
                            })}
                            error={!!(errors as any)?.numeroContratoIncorporator}
                            helperText={
                                (errors as any)?.numeroContratoIncorporator?.message
                            }
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            label={translate(
                                "pages.pedidos.fields.numeroContratoIncorporator",
                            )}
                            name="numeroContratoIncorporator"
                        />                                                                                          */}
                
                </Grid>
            </Box>
        </Create>
    );
};
